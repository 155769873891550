export default {
  somethingWentWrong: 'Something went wrong, please try again',
  phoneNumberInvalid: 'Phone number format is invalid',
  phoneNumberAlreadyAdded: 'The phone you have entered has already received a gift',
  phoneNumberIsNotANewCustomer: 'This was not your first visit',
  phonenNumberIsEmpty: 'Please enter your phone number',
  emailSentSuccessfully: 'Email sent successfully',
  congrats: 'Congrats!',
  youReceivedPoints: 'You won 20 points!',
  youReceivedGift: 'You won a Cake',
  hurryUp: 'HURRY UP',
  giftsLeft: 'gifts left',
  phoneLabel: 'Your phone',
  emailLabel: 'Enter your e-mail address',
  submitPhoneNumberForPoints: 'Get free points!',
  submitPhoneNumberForGift: 'Get a free gift!',
  emailInvalid: 'Please enter a valid email address',
  submitEmailRemind: 'Send',
  goToEmailReminderPage: 'Email me the download link',
  thankYou: 'Thank You',
  downloadApp: 'To receive your gift download Koinz app NOW',
  campaignExpiredTitle: 'We are sorry',
  campaignExpiredSubTitle: 'campaignExpiredSubTitle',
  errorCustomerBlocked: 'errorCustomerBlocked',
}
