import { RouteComponentProps, Route, Redirect } from "react-router-dom";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import PhoneEntry from "../MiniApp/PhoneEntry";
import MiniApp from "../MiniApp";
import { MODES } from "..";
import ThankYou from "../MiniApp/ThankYou";
import { hot } from "react-hot-loader";
// @ts-ignore
import {
  ICampaignData,
  getCampaignData,
  points_type,
  CampaignLanguage,
  CampaignType,
} from "../../../axios/getCampaignData";
// @ts-ignore
import {
  campaignCustomer,
  specialOTOCampaignCustomer,
} from "../../../axios/CampaignCustomer";
import normalizeReferrer from "../helpers/normalizeReferrer";
import { notify } from "react-notify-toast";
import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import BounceLoader from "react-spinners/BounceLoader";
// @ts-ignore
import localforage from "localforage";
// @ts-ignore
import { remindMeLater } from "../../../axios/sendEmailReminder";
// @ts-ignore
import { useTranslation } from "react-i18next";
// @ts-ignore
import AppStrings from "../../../i18n/strings/App";
import { useUserLanguage } from "./hooks";
// @ts-ignore
import { getUserCity } from "../../../axios/getUserCity";
// @ts-ignore
import { addCampaignClick } from "../../../axios/addCampaignClick";
import CampaignFinished from "../MiniApp/CampaignFinished";
// @ts-ignore
import { getOS } from "../../../../Utils/getOS";
import SpecialOTOEntry from "../MiniApp/SpecialOTOEntry";
import ThankYouSpecialOTO from "../MiniApp/ThankYouSpecialEntry";
import { useRecoilState } from "recoil";
import { remoteAuthenticationAtom } from "../atoms";

const ERROR_CODE_CAMPAIGN_EXPIRED = 0;
const ERROR_CODE_PHONE_FORMAT_INVALID = 1;
const ERROR_CODE_PHONE_ALREADY_REGISTERED = 2;
const ERROR_CODE_CUSTOMER_IS_NOT_NEW_TO_THE_BRAND = 3;
const ERROR_CODE_CUSTOMER_IS_BLOCKED = 4;

interface ILiveAppWrapperProps
  extends RouteComponentProps<{
    id: string;
    is_remote_authentication?: string;
  }> {
  storeImage: string;
}

export const useIsCampaignExpired = (
  days_left: number,
  campaignCustomersNumber: number,
  max_users: string,
  isInfinite: boolean
) => {
  return useMemo(() => {
    const isPastExpirationDate = days_left <= 0;
    const hasReachedTarget =
      !isInfinite && campaignCustomersNumber >= +max_users;
    return isPastExpirationDate || hasReachedTarget;
  }, [days_left, campaignCustomersNumber, max_users, isInfinite]);
};

export const isCampaignIfinite = (max_users: string) =>
  max_users === "infinity";

const getUserLocation = async () => {
  try {
    const {
      data: { city, country_name },
    } = await getUserCity();
    return {
      city,
      country_name,
    };
  } catch (e) {
    return {};
  }
};

const LiveAppWrapper: React.FC<ILiveAppWrapperProps> = ({
  match: {
    url,
    params: { id: campaignId, is_remote_authentication },
  },
  history,
}) => {
  const userOS = useMemo(getOS, []) as any;

  const [, setErrorMessage] = useState<string | undefined>(undefined);
  const [, setRemoteAuthentication] = useRecoilState(remoteAuthenticationAtom);
  useEffect(() => {
    if (is_remote_authentication) {
      setRemoteAuthentication(true);
    }
  }, [is_remote_authentication]);

  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);
  const [campaignData, setCampaignData] = useState<ICampaignData>({
    campaign: {
      type: CampaignType.normal_OTO,
      campaign_language: CampaignLanguage.en,
      campaign_logo: "",
      campaign_page_description: "",
      campaign_page_main_color: "",
      campaign_page_text_color: "",
      campaign_page_title: "",
      customers_count: 0,
      max_users: "",
      days_left: Infinity,
      campaign_page_download_title: "",
      gift: {
        type: points_type,
        data: {
          points: 10,
        },
      },
    },
    store: {
      image: "",
      country_iso_code: "sa",
    },
  });

  const {
    campaign: {
      campaign_page_main_color: backgroundColor,
      campaign_page_text_color: textColor,
      campaign_page_description,
      campaign_page_title,
      customers_count: current_users_count,
      days_left,
      campaign_page_download_title,
      gift,
    },
    store: { image },
  } = campaignData;
  const max_users = campaignData.campaign.max_users.toString();
  const isInfinite = useMemo(() => isCampaignIfinite(max_users), [max_users]);
  const campaignIsExpired = useIsCampaignExpired(
    days_left === undefined ? Infinity : days_left,
    current_users_count,
    max_users,
    isInfinite
  );

  const storeImage = campaignData.campaign.campaign_logo
    ? process.env.LUMEN_ENDPOINT + campaignData.campaign.campaign_logo
    : image
    ? process.env.ENDPOINT + image
    : "";

  const errorMsgsMap = useMemo(() => {
    return {
      [ERROR_CODE_PHONE_FORMAT_INVALID]: AppStrings.phoneNumberInvalid,
      [ERROR_CODE_PHONE_ALREADY_REGISTERED]:
        campaignData.campaign.campaign_language === CampaignLanguage.en
          ? "This user is not eligible for this campaign"
          : "رقم العميل الذي أدخلته غير مؤهل للحصول على هذه الهدية",
      [ERROR_CODE_CUSTOMER_IS_NOT_NEW_TO_THE_BRAND]:
        AppStrings.phoneNumberIsNotANewCustomer,
      [ERROR_CODE_CUSTOMER_IS_BLOCKED]: AppStrings.errorCustomerBlocked,
      [ERROR_CODE_CAMPAIGN_EXPIRED]: AppStrings.campaignExpiredSubTitle,
    };
  }, [campaignData.campaign.campaign_language]);

  const {
    loading,
    success,
    setLoading,
    setSuccess,
    setFailure,
  } = useProgressState();

  const registerUserVisit = useCallback(async () => {
    await localforage.setDriver([
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ]);

    const hasAlreadyVisited = await localforage.getItem(campaignId);

    if (!hasAlreadyVisited && !campaignIsExpired) {
      await addCampaignClick(campaignId);
      await Promise.resolve(campaignId);
      await localforage.setItem(campaignId, true);
    }
  }, [campaignId, campaignIsExpired]);

  const getAppData = useCallback(async () => {
    await registerUserVisit();
    const { data } = await getCampaignData(campaignId);
    return data;
  }, [campaignId, registerUserVisit]);

  useEffect(() => {
    if (campaignId) {
      setLoading();
      getAppData()
        .then((data) => {
          setSuccess();
          setCampaignData(data);
        })
        .catch(setFailure);
    }
  }, [campaignId, campaignIsExpired]);
  const goToThankYou = useCallback(() => history.push(`${url}/finished`), []);
  const { t } = useTranslation();
  const handleAddPhoneNumber = useCallback(
    async (phone_number: string) => {
      notify.hide();
      try {
        const { city, country_name } = await getUserLocation();
        await campaignCustomer({
          campaign_id: campaignId,
          phone_number,
          location: city || country_name || undefined,
          source: normalizeReferrer(document.referrer) || "url-directly",
        });
        goToThankYou();
      } catch (err) {
        if (err && err.response && err.response.status === 422) {
          const errorCode = err.response.data.code;
          const errorMessage = errorMsgsMap[errorCode];
          return errorMessage !== undefined
            ? notify.show(t(errorMessage), "error")
            : undefined;
        }
      }
    },
    [goToThankYou]
  );

  const handleSpecialOTOSend = useCallback(
    async (
      sender_name: string,
      receiver_name: string,
      phone_number: string
    ) => {
      notify.hide();
      try {
        const { city, country_name } = await getUserLocation();
        await specialOTOCampaignCustomer({
          campaign_id: campaignId,
          sender_name,
          receiver_name,
          phone_number,
          location: city || country_name || undefined,
          source: normalizeReferrer(document.referrer) || "url-directly",
        });
        goToThankYou();
      } catch (err) {
        if (err && err.response && err.response.status === 422) {
          const errorCode = err.response.data.code;
          setErrorCode(errorCode);
          const error_message = errorMsgsMap[errorCode];
          return error_message !== undefined
            ? setErrorMessage(t(error_message))
            : undefined;
        }
      }
    },
    [goToThankYou]
  );

  const handleSendEmailReminder = useCallback(
    (email: string) => {
      return remindMeLater(email).then(() => {
        goToThankYou();
        notify.show(t(AppStrings.emailSentSuccessfully), "success");
      });
    },
    [goToThankYou]
  );
  const renderPhoneEntry = useCallback(() => {
    return (
      <PhoneEntry
        formatText={t}
        onSendPhoneNumber={handleAddPhoneNumber}
        isPointsIncentive={gift.type === points_type}
        campaign={{
          backgroundColor,
          textColor,
          campaign_page_description,
          campaign_page_title,
          current_users_count,
          max_users,
        }}
        store={{
          image: storeImage,
          country_iso_code: campaignData.store.country_iso_code,
        }}
        mode={MODES.LIVE_APP}
      />
    );
  }, [campaignData, gift]);

  const renderSpecialOTOEntry = useCallback(() => {
    return (
      <SpecialOTOEntry
        formatText={t}
        onSend={handleSpecialOTOSend}
        isPointsIncentive={gift.type === points_type}
        campaign={{
          campaign_language: campaignData.campaign.campaign_language,
          backgroundColor,
          textColor,
          campaign_page_description,
          campaign_page_title,
          current_users_count,
          max_users,
        }}
        store={{
          image: storeImage,
          country_iso_code: campaignData.store.country_iso_code,
        }}
        mode={MODES.LIVE_APP}
        errorCode={errorCode}
        setErrorCode={setErrorCode}
      />
    );
  }, [campaignData, gift, errorCode]);

  const renderThankYou = useCallback(
    () => (
      <ThankYou
        download_title={campaign_page_download_title}
        formatText={t}
        OS={userOS}
        campaign_gift_name={gift.data.gift_name}
        isPointsIncentive={gift.type === points_type}
        campaignPoints={gift.data.points}
        backgroundColor={backgroundColor}
        store={{
          image: storeImage,
        }}
        mode={MODES.LIVE_APP}
        onSendEmail={handleSendEmailReminder}
      />
    ),
    [storeImage, gift]
  );

  const renderSpecialOTOThankYou = useCallback(
    () => (
      <ThankYouSpecialOTO
        download_title={campaign_page_download_title}
        formatText={t}
        OS={userOS}
        campaign_gift_name={gift.data.gift_name}
        isPointsIncentive={gift.type === points_type}
        campaignPoints={gift.data.points}
        backgroundColor={backgroundColor}
        store={{
          image: storeImage,
        }}
        mode={MODES.LIVE_APP}
        onSendEmail={handleSendEmailReminder}
        campaignLanguage={campaignData.campaign.campaign_language}
        secondPageHeading={campaign_page_download_title}
      />
    ),
    [storeImage, gift]
  );

  const renderCampaignFinished = useCallback(
    () => (
      <CampaignFinished
        formatText={t}
        campaign_language={campaignData.campaign.campaign_language}
        store={{
          image: storeImage,
        }}
      />
    ),
    [storeImage]
  );

  const [isInitial, setIsInitial] = React.useState(true);

  useUserLanguage();
  React.useEffect(() => {
    setIsInitial(false);
  }, []);
  return (
    <MiniApp
      backgroundColor={campaignData.campaign.campaign_page_main_color}
      mode={MODES.LIVE_APP}
      textColor={campaignData.campaign.campaign_page_text_color}
    >
      {loading && <BounceLoader color="#fead12" />}
      {success && (
        <>
          {campaignIsExpired && <Route render={renderCampaignFinished} />}
          {!campaignIsExpired && (
            <>
              <Route
                render={
                  campaignData.campaign.type === CampaignType.special_event_OTO
                    ? renderSpecialOTOEntry
                    : renderPhoneEntry
                }
                exact={true}
                path={`${url}/registration`}
              />
              <Route
                render={
                  campaignData.campaign.type === CampaignType.special_event_OTO
                    ? renderSpecialOTOThankYou
                    : renderThankYou
                }
                exact={true}
                path={`${url}/finished`}
              />
              <Redirect
                from={`${url}/`}
                exact={true}
                to={`${url}/registration`}
              />
              {isInitial && <Redirect to={`/${campaignId}/registration`} />}
            </>
          )}
        </>
      )}
    </MiniApp>
  );
};

export default hot(module)(LiveAppWrapper);
