import React from "react";
import * as styles from "../ThankYou/styles.module.sass";
import { useKindaTranslate } from "../PhoneEntry";
// @ts-ignore
import AppStrings from "../../../../i18n/strings/App";

interface ICampaignFinishedProps {
  formatText?: (str: string, options?: { count: number }) => string;
  store: {
    image: string;
  };
  campaign_language?: string;
}

// Finished campaign
const CampaignFinished: React.FC<ICampaignFinishedProps> = ({
  formatText,
  store: { image },
  campaign_language,
}) => {
  const t = useKindaTranslate(formatText);
  return (
    <>
      <div className={`${styles.mainContent} ${styles.liveApp}`}>
        <div className={styles.campaignData}>
          <img src={image} className={styles.storeImage} />
          <h1 className={styles.campaignTitle}>
            {campaign_language === "ar" ? t(" نعتذر لك") : t("We are sorry")}
          </h1>
          {/*  */}
          <p className={styles.campaignDescription}>
            {campaign_language === "ar"
              ? "انتهى العرض في الوقت الحالى، سنوافيكم بالعروض الجديدة قريبا"
              : `the campaign has ended, we will keep you updated with our upcoming offers.`}
          </p>
        </div>
      </div>
    </>
  );
};

export default CampaignFinished;
