import { SocialLinksEnum } from "./lib";

const FACEBOOK_LINK = /^https?:\/\/([^\/]+\.)?facebook\.com/i;
const INSTAGRAM_LINK = /^https?:\/\/([^\/]+\.)?instagram\.com/i;
const TWITTER_LINK = /^https?:\/\/t\.co/i;
const OTHER_URL = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)?)/;

const createNormalizer = (regex: RegExp, socialLinkLabel: SocialLinksEnum) =>
  (referrer: string) =>
    regex.test(referrer) ? socialLinkLabel : null;


const facebookNormalizer = createNormalizer(FACEBOOK_LINK, SocialLinksEnum.FACEBOOK);
const twitterNormalizer = createNormalizer(TWITTER_LINK, SocialLinksEnum.TWITTER);
const instagramNormalizer = createNormalizer(INSTAGRAM_LINK, SocialLinksEnum.INSTAGRAM);

const normalizers = [
  facebookNormalizer,
  twitterNormalizer,
  instagramNormalizer,
]

const processOtherURL = (referrer: string) => {
  const referrerURLMatch = referrer.match(OTHER_URL);
  return referrerURLMatch && referrerURLMatch[0];
}

const normalizeReferrer = (referrer: string) => {
  const popularSiteReferrer = normalizers.reduce((value, currentNormalizer) => {
    return value || currentNormalizer(referrer);
  }, null);
  return popularSiteReferrer || processOtherURL(referrer);
}

export default normalizeReferrer;
