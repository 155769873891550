import AppStrings from "../strings/App";

export default {
  translations: {
    [AppStrings.somethingWentWrong]: "Something went wrong, please try again",
    [AppStrings.phoneNumberInvalid]: "Phone number format is invalid",
    [AppStrings.phoneNumberAlreadyAdded]:
      "This user is not eligible for this campaign",
    [AppStrings.phoneNumberIsNotANewCustomer]:
      "Sorry, this campaign is for first time visitors only.",
    [AppStrings.phonenNumberIsEmpty]: "Please enter your phone number",
    [AppStrings.emailSentSuccessfully]: "Email sent successfully",
    [AppStrings.hurryUp]: "HURRY UP",
    [AppStrings.giftsLeft]: "{{count}} gift left",
    [`${AppStrings.giftsLeft}_plural`]: "{{count}} gifts left",
    [AppStrings.phoneLabel]: "Your phone",
    [AppStrings.emailLabel]: "Enter your e-mail address",
    [AppStrings.submitPhoneNumberForPoints]: "Get free points!",
    [AppStrings.submitPhoneNumberForGift]: "Get a free gift!",
    [AppStrings.emailInvalid]: "Please enter a valid email address",
    [AppStrings.submitEmailRemind]: "Send",
    [AppStrings.goToEmailReminderPage]: "Email me the download link",
    [AppStrings.thankYou]: "Thank You",
    [AppStrings.downloadApp]: "To receive your gift download Koinz app NOW",
    [AppStrings.campaignExpiredTitle]: "We are sorry",
    [AppStrings.campaignExpiredSubTitle]:
      "The offer has expired. You can follow us on social media for more offers.",
    [AppStrings.errorCustomerBlocked]:
      "Your number is blocked, please contact us to resolve your issue.",
    [AppStrings.congrats]: "Congrats!",
    [AppStrings.youReceivedPoints]: "You won {{count}} points",
    [AppStrings.youReceivedGift]: "You won {{campaign_gift_name}}"
  }
};
