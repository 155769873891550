import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.sass';
import './i18n/i18n';
import './fonts.css';
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);

