import instance from "./axios";

export const getUserCity = () => {
  return instance.get<{city: string | null; country_name: string}>('https://api.ipdata.co', {
    baseURL: '',
    params: {
      'api-key': process.env.IPDATA_API_KEY,
      'fields': 'city,country_name',
    }
  })
}
