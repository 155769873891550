import AppStrings from '../strings/App';

export default {
  translations: {
    [AppStrings.somethingWentWrong]: 'حدث خطأٌ ما، برجاء اعادة المحاولة',
    [AppStrings.phoneNumberInvalid]: 'رقم الجوال الذي أدخلته غير صحيح',
    [AppStrings.phoneNumberAlreadyAdded]: 'رقم الجوال الذي أدخلته قد استلم هدية بالفعل',
    [AppStrings.phoneNumberIsNotANewCustomer]: 'نحن آسفون، لكن هذا العرض ساري لعملائنا الجدد فقط',
    [AppStrings.phonenNumberIsEmpty]: 'رجاءا قم بادخال رقم جوالك',
    [AppStrings.emailSentSuccessfully]: 'تم ارسال البريد الالكتروني بنجاح!',
    [AppStrings.hurryUp]: 'أسرع',
    [AppStrings.giftsLeft]: 'تبقى {{count}} هدية',
    [`${AppStrings.giftsLeft}_1`]: 'تبقى هدية واحدة فقط!',
    [`${AppStrings.giftsLeft}_2`]: 'تبقى هديتان فقط',
    [`${AppStrings.giftsLeft}_3`]: 'تبقى {{count}} هدايا',
    [AppStrings.phoneLabel]: 'رقم جوالك',
    [AppStrings.emailLabel]: 'أدخل بريدك الالكتروني',
    [AppStrings.submitPhoneNumberForPoints]: 'احصل على النقاط المجانية',
    [AppStrings.submitPhoneNumberForGift]: 'احصل على الهدية المجانية',
    [AppStrings.emailInvalid]: 'رجاءاً قم بادخال بريد الكتروني صحيح',
    [AppStrings.submitEmailRemind]: 'أرسل',
    [AppStrings.goToEmailReminderPage]: 'أرسل لي بريداً الكترونيا برابط التحميل',
    [AppStrings.thankYou]: 'شكراً لك',
    [AppStrings.downloadApp]: 'لتستلم هديتك قم بتنزيل تطبيق Koinz اﻵن',
    [AppStrings.campaignExpiredTitle]: 'عفواً لقد انتهى العرض',
    [AppStrings.campaignExpiredSubTitle]: 'تابعنا على صفحات التواصل الاجتماعي لمعرفة المزيد من العروض.',
    [AppStrings.errorCustomerBlocked]: 'رقم جوالك تم حظره من المتجر، قم بالتواصل معنا لمتابعة مشكلتك.',
    [AppStrings.congrats]: 'تهانينا!',
    [AppStrings.youReceivedPoints]: 'لقد فزت ب {{count}} نقطة',
    [AppStrings.youReceivedGift]: 'لقد فزت ب {{campaign_gift_name}}',
  },
};
