import axios, {AxiosError, AxiosResponse} from "axios";
// import {logout} from "../redux-store/actions/logout-actions";
// import {store} from "../redux-store/configureStore";
// import {switchCashier} from "./requests";

const handleSuccess = (res: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => res;
const handleError = (error: AxiosError) => {
  // const isSwitchingAccount: boolean = error.config.url === process.env.ENDPOINT + switchCashier;
  // if ((error.response as AxiosResponse).status === 401 && !isSwitchingAccount) {
  //   store.dispatch(logout());
  // }
    throw error;
};

const instance = axios.create({
  baseURL: process.env.LUMEN_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  }

});

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;
