import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

interface ICampaignCustomer {
  campaign_id: string;
  phone_number: string;
  location?: string;
  source: string;
}

interface ISpecialOTOCampaignCustomer {
  campaign_id: string;
  sender_name: string;
  receiver_name: string;
  phone_number: string;
  location?: string;
  source: string;
}

export const campaignCustomer = (data: ICampaignCustomer): AxiosReturn<{}> => {
  return axios.post(Requests.campaignCustomer, data);
};

export const specialOTOCampaignCustomer = (
  data: ISpecialOTOCampaignCustomer
): AxiosReturn<{}> => {
  return axios.post(Requests.specialOTOCampaignCustomer, data);
};
