type OSType = 'IOS' | 'ANDROID' | null;

export const getOS = (): OSType => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os: OSType = null;

  if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'IOS';
  } else if (/Android/.test(userAgent)) {
    os = 'ANDROID';
  }

  return os;
};
