import * as React from "react";
import * as styles from "./App.module.sass";
import Notifications from "react-notify-toast";
import { useTranslation } from "react-i18next";
import LiveAppWrapper from "./components/oto-campaign-mini-app/LiveAppWrapper";
import { HashRouter, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { remoteAuthenticationAtom } from "./components/oto-campaign-mini-app/atoms";

export const getRtlClass = (i18n, stylesModule) => {
  const isRtl = i18n.language === "ar";
  const rtlClass = stylesModule.rtl;
  const hasRtlClass = !!stylesModule.rtl;
  const shouldReturnRtlClass = isRtl && hasRtlClass;
  return shouldReturnRtlClass ? rtlClass : "";
};

export const useRtlClass = stylesModule => {
  const { i18n } = useTranslation();

  return React.useMemo(() => getRtlClass(i18n, stylesModule), [i18n.language]);
};

const App: React.FC = props => {
  const isRemoteAuthentication = useRecoilValue(remoteAuthenticationAtom);
  return (
    <HashRouter>
      <div className={`${styles.App} ${useRtlClass(styles)}`}>
        {isRemoteAuthentication && (
          <div className={styles.dashboardPreview}>
            <span>Live Preview</span>
          </div>
        )}
        <Notifications
          options={{ zIndex: 2000, timeout: 5000, wrapperId: "dee-wrapper" }}
        />
        {
          isRemoteAuthentication ? (
            <React.Fragment>
              <Route
                path="/c/:id/:is_remote_authentication"
                component={LiveAppWrapper}
              />
              <Route
                path="/c-special/:id/:is_remote_authentication"
                component={LiveAppWrapper}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route
                path="/c/:id"
                component={LiveAppWrapper}
              />
              <Route
                path="/c-special/:id"
                component={LiveAppWrapper}
              />
            </React.Fragment>
          )
        }
      </div>
    </HashRouter>
  );
};

export default App;
