import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en";
import ar from "./ar";
import { initReactI18next } from "react-i18next";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    resources: {
      en,
      ar
    },
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;
