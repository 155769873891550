// @ts-ignore
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";

// get user language
export const useUserLanguage = () => {
  const lang = window.navigator.language;
  const { i18n } = useTranslation();

  const langKey = useMemo(() => lang.substr(0, 2), [lang]);
  useEffect(() => {
    i18n.changeLanguage(langKey);
  }, [langKey]);
};
